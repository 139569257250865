<template>
  <div class="public">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item >公告管理</el-breadcrumb-item>
    </el-breadcrumb>-->
    <el-form :inline="true" class="form-content">
      <el-form-item label="标题">
        <el-input placeholder="输入关键字" v-model="planName" class="wd200"></el-input>
      </el-form-item>
      <el-form-item label="发布人">
        <el-select placeholder="请选择发布人" v-model="createBy" clearable style="width:200px;">
          <el-option
            v-for="(item,index) in userList"
            :key="index"
            :value="item.organBusiness.id"
            :label="item.organBusiness.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="search-list">
      <el-button type="primary" class="mt20" @click="add" v-points>添加</el-button>
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column prop="noticeNumber" label="编号"></el-table-column>
        <el-table-column v-if="classify==2" prop="noticeTypeName" label="分类"></el-table-column>
        <el-table-column prop="noticeTitle" label="标题"></el-table-column>
        <el-table-column prop="viewNum" label="已查看"></el-table-column>
        <el-table-column prop="creator" label="发布人"></el-table-column>
        <el-table-column prop="publishTime" label="发布时间"></el-table-column>
        <el-table-column prop="state" label="已发布"></el-table-column>
        <el-table-column label="操作" fixed="right" align="right" header-align="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goToInfo(scope.row)">详情</el-button>
            <el-button
              type="text"
              size="small"
              @click="goToEdit(scope.row)"
              v-if="scope.row.state!='已发布'"
            >修改</el-button>
            <el-button type="text" size="small" @click="del(scope.row)" style="color:#f56c6c">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "public",

  data() {
    return {
      noticeTitle: "",
      noticeType: "",
      createBy: "",
      createTime: "",
      userList: [],
      typeList: [],
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateTime: [],
      moment: "",
      classify: "",
    };
  },

  watch: {
    noticeType: "getList",
    "$route.name": function(v) {
      console.log(v, "vvvvvv");
      if (v == "public") {
        this.noticeType = 5;
      } else if (v == "public2") {
        this.noticeType = 6;
      } else if (v == "public3") {
        this.noticeType = 7;
      }
      if (v == "notice") {
        this.classify = 1;
      } else {
        this.classify = 2;
      }
      // this.getList()
    },
  },

  created() {},

  async mounted() {
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"));
    this.moment = moment().format("YYYY-MM-DD HH:mm:ss");
    if (this.$route.name == "public") {
      this.noticeType = 5;
    } else if (this.$route.name == "public2") {
      this.noticeType = 6;
    } else if (this.$route.name == "public3") {
      this.noticeType = 7;
    }
    if (this.$route.name == "notice") {
      this.classify = 1;
    } else {
      this.classify = 2;
    }
    await this.getTypeList();
    await this.getUserList();
    await this.getList();
    console.log(this.noticeType, "this.noticeType-----------------");
  },

  methods: {
    async getList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/notice/list"),
        method: "post",
        data: {
          stringParam1: this.noticeTitle,
          // hospitalId:user.deptPid,
          // deptId::user.deptId,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
          stringParam3: this.createBy,
          stringParam6: this.dateTime[0],
          stringParam7: this.dateTime[1],
          intParam5: this.classify == 2 ? this.noticeType : "",
          stringParam4: teamsId,
          stringParam5: enertyId,
          intParam4: this.classify,
        },
      });
      if (res.status) {
        res.data.forEach((v) => {
          this.userList.forEach((user) => {
            if (v.createBy == user.organBusiness.id) {
              v.creator = user.organBusiness.name;
            }
          });
          console.log(
            this.moment,
            v.publishTime,
            v.publishTime > this.moment,
            v.publishTime < this.moment
          );
          if (v.publishTime < this.moment) {
            v.state = "已发布";
          } else {
            v.state = "未发布";
          }
          // this.diseasesList.forEach(v2=>{
          //   if(v.workEnertyType==v2.value){
          //     v.disease=v2.name
          //   }
          // })
          this.typeList.forEach((type) => {
            if (v.noticeType == type.value) {
              v.workTypeName = type.name;
            }
          });
        });
        this.dataList = res.data;
        this.totalPage = res.totalCount;
      }
      this.dataListLoading = false;
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '公告分类',
		
		},
       
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    add() {
      if (this.classify == 2) {
        if (this.noticeType == 5) {
          this.$router.push({
            path: "/public/add",
            query: { type: this.noticeType },
          });
        }
        if (this.noticeType == 6) {
          this.$router.push({
            path: "/public2/add",
            query: { type: this.noticeType },
          });
        }
        if (this.noticeType == 7) {
          this.$router.push({
            path: "/public3/add",
            query: { type: this.noticeType },
          });
        }
      } else {
        this.$router.push({ path: "/notice/add" });
      }
    },
    async del(row) {
      console.log(row, "row");
      const id = row.id;
      this.$confirm("确定进行[删除公告]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          // this.dataListSelections.forEach(v=>{
          //   ids.push(v.id)
          // })
          // const id = ids.join(`,`)

          const { data: res } = await this.$httpAes({
            url: this.$http.adornUrl(`/notice/delete?ids=${id}`),
            method: "post",
            data:{
            	stringParam1:id
            }
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除公告成功");
            this.getList();
          }
        })
        .catch(() => {});
    },
    goToEdit(row) {
      this.$router.push({ path: "/public/edit", query: { id: row.id } });
    },
    async getUserList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/organBusiness/backUserList"),
        method: "post",
        params: {
          hospitalId: user.deptPid,
        },
      });
      console.log(res, "user res");
      this.userList = res.data;
    },
    goToInfo(row) {
      this.$router.push({ path: "/public/info", query: { id: row.id } });
    },
    reset() {
      this.noticeTitle = "";
      this.noticeType = "";
      this.createBy = "";
      this.createTime = "";
      this.dateTime = [];
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.public {
  .search-list {
    display: flex;
    align-items: center;
    .label {
      width: 5%;
    }
  }
}
</style>